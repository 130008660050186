








































































































































































































































































































































































































































































































import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import i18nDb, { I18nDbCompiledTemplate } from '@/services/i18n-db';
import type { I18nDb } from '@/services/i18n-db';
import ItemEditorTestPanel from './ItemEditorTestPanel.vue';
import type { DictTranslate } from './types';


const modelChangeEvent = 'change';


@Component({
    components: {
        ItemEditorTestPanel,
    },
})
export default class ItemEditor extends Vue {
    // region Модель, свойства
    @Model(
        modelChangeEvent,
        {
            type: Object,
            required: true,
        },
    )
    private readonly value!: DictTranslate;

    @Prop({
        type: Boolean,
        required: true,
    })
    private readonly newItem!: boolean;

    @Prop({
        type: Object,
        required: true,
    })
    private readonly enUiData!: I18nDb.LoadedData;

    @Prop({
        type: Object,
        required: true,
    })
    private readonly kkUiData!: I18nDb.LoadedData;

    @Prop({
        type: Object,
        required: true,
    })
    private readonly ruUiData!: I18nDb.LoadedData;
    // endregion


    // region Утилиты
    private t(key: string, ...args: Array<unknown>): string {
        return i18nDb.translateByKey(key, ...args);
    }

    private tt(template: string, ...args: Array<unknown>): string {
        return i18nDb.translateByTemplate(template, ...args);
    }
    // endregion


    // region Изменение данных
    private change(changing: (newValue: DictTranslate) => unknown) {
        const newValue: DictTranslate = {...this.value};
        changing(newValue);
        this.$emit(modelChangeEvent, newValue);
    }

    private onCodeChanged(value: string) {
        this.change((newValue) => {
            newValue.code = value;
        });
    }

    private onEnChanged(value: string) {
        this.change((newValue) => {
            newValue.en = value;
        });
    }

    private onKkChanged(value: string) {
        this.change((newValue) => {
            newValue.kk = value;
        });
    }

    private onRuChanged(value: string) {
        this.change((newValue) => {
            newValue.ru = value;
        });
    }

    private onDescriptionChanged(value: string) {
        this.change((newValue) => {
            newValue.description = value;
        });
    }
    // endregion


    // region Проверка
    private get enCompiledTemplate(): I18nDbCompiledTemplate {
        return I18nDbCompiledTemplate.create(this.value.code, this.value.en, this.value.en);
    }

    private get kkCompiledTemplate(): I18nDbCompiledTemplate {
        return I18nDbCompiledTemplate.create(this.value.code, this.value.kk, this.value.kk);
    }

    private get ruCompiledTemplate(): I18nDbCompiledTemplate {
        return I18nDbCompiledTemplate.create(this.value.code, this.value.ru, this.value.ru);
    }

    private get enArgTypes(): Array<I18nDb.CompiledArgType> {
        return this.enCompiledTemplate.argTypes;
    }

    private get kkArgTypes(): Array<I18nDb.CompiledArgType> {
        return this.kkCompiledTemplate.argTypes;
    }

    private get ruArgTypes(): Array<I18nDb.CompiledArgType> {
        return this.ruCompiledTemplate.argTypes;
    }

    private get enErrorInfo(): I18nDb.Validation.ErrorInfo | null {
        return this.enCompiledTemplate.validationErrorInfo ?? null;
    }

    private get kkErrorInfo(): I18nDb.Validation.ErrorInfo | null {
        return this.kkCompiledTemplate.validationErrorInfo ?? null;
    }

    private get ruErrorInfo(): I18nDb.Validation.ErrorInfo | null {
        return this.ruCompiledTemplate.validationErrorInfo ?? null;
    }

    private errorInfoText(errorInfo: I18nDb.Validation.ErrorInfo): string {
        let message: string;
        switch (i18nDb.locale) {
            case 'en':
                message = errorInfo.en;
                break;
            case 'kk':
                message = errorInfo.kk;
                break;
            default:
                message = errorInfo.ru;
                break;
        }

        return `[${errorInfo.range.start} - ${errorInfo.range.end}] ${message}`;
    }

    private get enErrorText(): string | null {
        const errorInfo = this.enErrorInfo;
        if (errorInfo === null) {
            return null;
        } else {
            return this.errorInfoText(errorInfo);
        }
    }

    private get kkErrorText(): string | null {
        const errorInfo = this.kkErrorInfo;
        if (errorInfo === null) {
            return null;
        } else {
            return this.errorInfoText(errorInfo);
        }
    }

    private get ruErrorText(): string | null {
        const errorInfo = this.ruErrorInfo;
        if (errorInfo === null) {
            return null;
        } else {
            return this.errorInfoText(errorInfo);
        }
    }
    // endregion


    // region Модальное окно - описание аргументов
    private argumentDescriptionVisible = false;
    // endregion
}
