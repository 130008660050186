













































import { Component, Prop, Vue } from 'vue-property-decorator';
import i18nDb, { I18nDbCompiledTemplate } from '@/services/i18n-db';
import type { I18nDb } from '@/services/i18n-db';
import ItemEditorTestArgument from './ItemEditorTestArgument.vue';


const now = new Date();
const exampleValues = {
    string: 'Text',
    number: 123.1357,
    date: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 15, 4, 5),
};


@Component({
    components: {
        ItemEditorTestArgument,
    },
})
export default class ItemEditorTestPanel extends Vue {
    // region Свойства
    @Prop({
        type: String,
        required: true,
    })
    private readonly code!: string;

    @Prop({
        type: String,
        required: true,
    })
    private readonly template!: string;

    @Prop({
        type: Object,
        required: true,
    })
    private readonly uiData!: I18nDb.LoadedData;
    // endregion


    // region Lifecycle
    private created() {
        this.$watch('argTypes', () => {
            if (this.errorText === null) {
                this.resetArgumentValues();
            }
        });
    }

    private mounted() {
        this.resetArgumentValues();
    }
    // endregion


    // region Утилиты
    private t(key: string, ...args: Array<unknown>): string {
        return i18nDb.translateByKey(key, ...args);
    }
    // endregion


    // region Скомпилированный шаблон
    private get compiledTemplate(): I18nDbCompiledTemplate {
        return I18nDbCompiledTemplate.create(this.code, this.template, this.template);
    }

    private get errorText(): string | null {
        const info = this.compiledTemplate.validationErrorInfo;
        if (info === undefined) {
            return null;
        } else {
            let message: string;
            switch (i18nDb.locale) {
                case 'en':
                    message = info.en;
                    break;
                case 'kk':
                    message = info.kk;
                    break;
                default:
                    message = info.ru;
                    break;
            }

            return i18nDb.translateByKey('modules.i18n.editor.validationError', info.range.start, info.range.end, message);
        }
    }
    // endregion


    // region Аргументы
    private get argTypes(): Array<I18nDb.CompiledArgType> {
        return this.compiledTemplate.argTypes;
    }

    private argumentValues: Array<unknown> = [];

    private resetArgumentValues() {
        const newArgumentValues = [...this.argumentValues];
        while (newArgumentValues.length > this.argTypes.length) {
            newArgumentValues.splice(newArgumentValues.length - 1, 1);
        }
        while (newArgumentValues.length < this.argTypes.length) {
            newArgumentValues.push(exampleValues.string);
        }

        this.argTypes.forEach((argType, index) => {
            switch (argType) {
                case 'NUMBER':
                    if (typeof newArgumentValues[index] !== 'number') {
                        newArgumentValues.splice(index, 1, exampleValues.number);
                    }
                    break;
                case 'DATE':
                    if (!(newArgumentValues[index] instanceof Date)) {
                        newArgumentValues.splice(index, 1, exampleValues.date);
                    }
                    break;
                default:
                    if (typeof newArgumentValues[index] !== 'string') {
                        newArgumentValues.splice(index, 1, String(newArgumentValues[index]));
                    }
                    break;
            }
        });

        this.argumentValues = newArgumentValues;
    }

    private onArgumentValueChanged(index: number, value: unknown) {
        const newArgumentValues = [...this.argumentValues];
        newArgumentValues[index] = value;
        this.argumentValues = newArgumentValues;
    }

    private argTypeText(argType: I18nDb.CompiledArgType): string {
        return i18nDb.translateByKey(`modules.i18n.editor.type.${argType}`);
    }
    // endregion


    // region Результат применения шаблона
    private get result(): string {
        if (this.argumentValues.length === this.argTypes.length) {
            return i18nDb.usingTemporary(this.uiData, () => {
                return this.compiledTemplate.toString(...this.argumentValues);
            });
        } else {
            return '...';
        }
    }
    // endregion
}
