















































































import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import type { I18nDb } from '@/services/i18n-db';
import i18nDb from '@/services/i18n-db';


const modelChangeEvent = 'change';


const hourOptions = ((): Array<{ text: string, value: number }> => {
    const result: Array<{ text: string, value: number }> = [];

    for (let hour = 0; hour < 24; hour++) {
        result.push({ text: String(hour), value: hour });
    }
    return result;
})();

const minuteOptions = ((): Array<{ text: string, value: number }> => {
    const result: Array<{ text: string, value: number }> = [];

    for (let minute = 0; minute < 60; minute++) {
        result.push({ text: String(minute), value: minute });
    }
    return result;
})();

// noinspection UnnecessaryLocalVariableJS
const secondOptions = minuteOptions;


@Component
export default class ItemEditorTestArgument extends Vue {
    // region Модель, свойства
    @Model(
        modelChangeEvent,
        {
            type: [String, Number, Date],
            required: true,
        }
    )
    private readonly value!: string | number | Date;

    @Prop({
        type: String,
        required: true,
    })
    private readonly argType!: I18nDb.CompiledArgType;
    // endregion


    // region Number value
    private get numberValue(): number | null {
        if (typeof this.value === 'number') {
            return this.value;
        } else {
            return null;
        }
    }

    private onNumberValueChanged(value: unknown) {
        let newValue: number;

        if (typeof value === 'number') {
            newValue = value;
        } else if (typeof value === 'string') {
            newValue = parseFloat(value);
            if (String(newValue) !== value.trim()) {
                return;
            }
        } else {
            newValue = 0;
        }

        if (!Number.isFinite(newValue)) {
            newValue = 0;
        }

        if (newValue !== this.value) {
            this.$emit(modelChangeEvent, newValue);
        }
    }

    private onNumberInputBlur(ev: FocusEvent) {
        const target = ev.target;
        if (!(target instanceof HTMLInputElement)) {
            return;
        }

        if (this.numberValue === null) {
            target.value = '0';
        } else {
            target.value = String(this.numberValue);
        }
    }
    // endregion


    // region Date value
    private resetDateValue: Date | null = null;

    private get dateValue(): Date | null {
        if (this.resetDateValue !== null) {
            return this.resetDateValue;
        }

        if (this.value instanceof Date) {
            return this.value;
        } else {
            return null;
        }
    }

    private get dateValueYear(): number | null {
        if (this.dateValue === null) {
            return null;
        } else {
            return this.dateValue.getFullYear();
        }
    }

    private get yearOptions(): Array<{ text: string, value: number }> {
        const result: Array<{ text: string, value: number }> = [];

        if (this.dateValueYear !== null) {
            const start = (this.dateValueYear - 10);
            const end = (this.dateValueYear + 10);

            for (let year = start; year <= end; year++) {
                result.push({
                    text: String(year),
                    value: year,
                });
            }
        }

        return result;
    }

    private get dateValueMonth(): number | null {
        if (this.dateValue === null) {
            return null;
        } else {
            return this.dateValue.getMonth();
        }
    }

    private get monthOptions(): Array<{ text: string, value: number }> {
        const result: Array<{ text: string, value: number }> = [];
        for (let month = 0; month < 12; month++) {
            const text = i18nDb.monthName(month);
            result.push({ text, value: month });
        }
        return result;
    }

    private get dateValueDay(): number | null {
        if (this.dateValue === null) {
            return null;
        } else {
            return this.dateValue.getDate();
        }
    }

    private get dayOptions(): Array<{ text: string, value: number }> {
        const result: Array<{ text: string, value: number }> = [];

        for (let day = 1; day <= 31; day++) {
            result.push({ text: String(day), value: day });
        }
        return result;
    }

    private get dateValueHour(): number | null {
        if (this.dateValue === null) {
            return null;
        } else {
            return this.dateValue.getHours();
        }
    }

    private hourOptions = hourOptions;

    private get dateValueMinute(): number | null {
        if (this.dateValue === null) {
            return null;
        } else {
            return this.dateValue.getMinutes();
        }
    }

    private minuteOptions = minuteOptions;

    private get dateValueSecond(): number | null {
        if (this.dateValue === null) {
            return null;
        } else {
            return this.dateValue.getSeconds();
        }
    }

    private secondOptions = secondOptions;

    private changeDateValue(changing: (newDateValue: Date) => unknown, targetMonth?: number) {
        const newDateValue = new Date(this.dateValue ?? Date.now());

        const oldMonth = newDateValue.getMonth();

        changing(newDateValue);

        let reset = false;
        if (newDateValue.getMonth() !== (targetMonth ?? oldMonth)) {
            this.resetDateValue = new Date(newDateValue);
            newDateValue.setDate(0);
            reset = true;
        }

        this.$emit(modelChangeEvent, newDateValue);

        if (reset) {
            setTimeout(() => {
                this.resetDateValue = null;
            });
        }
    }

    private onDateYearChanged(value: number) {
        this.changeDateValue((newDateValue) => {
            newDateValue.setFullYear(value);
        });
    }

    private onDateMonthChanged(value: number) {
        this.changeDateValue(
            (newDateValue) => {
                newDateValue.setMonth(value);
            },
            value,
        );
    }

    private onDateDayChanged(value: number) {
        this.changeDateValue((newDateValue) => {
            newDateValue.setDate(value);
        });
    }

    private onDateHourChanged(value: number) {
        this.changeDateValue((newDateValue) => {
            newDateValue.setHours(value);
        });
    }

    private onDateMinuteChanged(value: number) {
        this.changeDateValue((newDateValue) => {
            newDateValue.setMinutes(value);
        });
    }

    private onDateSecondChanged(value: number) {
        this.changeDateValue((newDateValue) => {
            newDateValue.setSeconds(value);
        });
    }
    // endregion


    // region String value
    private get stringValue(): string | null {
        if (typeof this.value === 'string') {
            return this.value;
        } else {
            return null;
        }
    }

    private onStringValueChanged(value: string) {
        if (value !== this.value) {
            this.$emit(modelChangeEvent, value);
        }
    }
    // endregion
}
