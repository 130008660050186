














































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import i18nDb from '@/services/i18n-db';
import type { I18nDb } from '@/services/i18n-db';
import Ax from '@/utils/ax';
import ItemCard from './ItemCard.vue';
import ItemEditor from './ItemEditor.vue';
import type { DictTranslate } from './types';


interface PaginationList<T> {
    /** Кол-во элементов на странице */
    itemsPerPage: number;

    /**
     * Текущая страница
     *
     * Нумерация с 0
     */
    page: number;

    /** Общее кол-во элементов */
    itemCount: number;

    /** Общее кол-во страниц */
    pageCount: number;

    /** Элементы для текущей страницы */
    items: Array<T>;
}


const emptyTranslate: DictTranslate = { code: '', en: '', kk: '', ru: '', description: '' };


const protectedCodes: Readonly<Set<string>> = Object.freeze(new Set([
    '_.date-time.first-day-of-week',
    '_.date-time.format-24h',
    '_.date-time.name.am',
    '_.date-time.name.pm',
    '_.date-time.template.date-time',
    '_.number.separator.fraction',
    '_.number.separator.thousands',
]));

const protectedCodePrefixes: ReadonlyArray<string> = Object.freeze([
    '_.date-time.name.month.',
    '_.date-time.name.month-day.',
    '_.date-time.name.month-day-short.',
    '_.date-time.name.month-short.',
    '_.date-time.name.week-day.',
    '_.date-time.name.week-day-short.',
    '_.date-time.template.date.',
    '_.date-time.template.time.',
]);

const isProtectedCode = (code: string): boolean => {
    if (protectedCodes.has(code)) {
        return true;
    }

    for (const protectedCodePrefix of protectedCodePrefixes) {
        if (code.startsWith(protectedCodePrefix)) {
            return true;
        }
    }

    return false;
};


@Component({
    components: {
        ItemCard,
        ItemEditor,
    },
})
export default class Page extends Vue {
    // region Lifecycle
    private created() {
        // region watch - Фильтры
        this.$watch('showServiceTemplates', () => {
            this.reloadTranslates();
        });
        this.$watch('preparedCodeFilter', () => {
            this.reloadTranslates();
        });
        this.$watch('preparedEnFilter', () => {
            this.reloadTranslates();
        });
        this.$watch('preparedKkFilter', () => {
            this.reloadTranslates();
        });
        this.$watch('preparedRuFilter', () => {
            this.reloadTranslates();
        });
        this.$watch('preparedDescriptionFilter', () => {
            this.reloadTranslates();
        });
        // endregion

        // region watch - Навигация по страницам
        this.$watch('itemPerPage', (itemPerPage: number, oldItemPerPage: number) => {
            if (this.page === 0) {
                this.reloadTranslates();
            } else {
                this.page = 0;
            }
        });
        this.$watch('page', (page: number, oldPage: number) => {
            this.reloadTranslates();
        });
        // endregion
    }

    private mounted() {
        this.reloadUiData();
        this.reloadTranslates();
    }
    // endregion


    // region Утилиты
    private toast(type: 'danger' | 'warning' | 'success', title: string, message: string) {
        this.$bvToast.toast(message, {
            title: title,
            variant: type,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private i18nDb = i18nDb;

    private t(key: string, ...args: Array<unknown>): string {
        return i18nDb.translateByKey(key, ...args);
    }
    // endregion


    private get loading(): boolean {
        return this.loadingUiData || this.loadingTranslates;
    }


    // region Данные для проверок переводов
    private enUiData: I18nDb.LoadedData | null = null;
    private kkUiData: I18nDb.LoadedData | null = null;
    private ruUiData: I18nDb.LoadedData | null = null;
    private loadingEnUiData = false;
    private loadingKkUiData = false;
    private loadingRuUiData = false;

    private get loadingUiData(): boolean {
        return (this.loadingEnUiData || this.loadingKkUiData || this.loadingRuUiData);
    }

    private reloadUiData() {
        if (this.loadingEnUiData || this.loadingKkUiData || this.loadingRuUiData) {
            console.error('Cannot reload UI data - another loading is running');
            return;
        }

        this.loadingEnUiData = true;
        this.loadingKkUiData = true;
        this.loadingRuUiData = true;

        Ax<I18nDb.LoadedData>(
            { url: `/api/i18n-db/data?locale=en` },
            (data) => {
                this.enUiData = data;
            },
            (error) => {
                this.toast('danger', i18nDb.translateByKey('modules.i18n.error.cannotLoadUiData', 'en'), error.toString());
            },
            () => {
                this.loadingEnUiData = false;
            },
        );
        Ax<I18nDb.LoadedData>(
            { url: `/api/i18n-db/data?locale=kk` },
            (data) => {
                this.kkUiData = data;
            },
            (error) => {
                this.toast('danger', i18nDb.translateByKey('modules.i18n.error.cannotLoadUiData', 'kk'), error.toString());
            },
            () => {
                this.loadingKkUiData = false;
            },
        );
        Ax<I18nDb.LoadedData>(
            { url: `/api/i18n-db/data?locale=ru` },
            (data) => {
                this.ruUiData = data;
            },
            (error) => {
                this.toast('danger', i18nDb.translateByKey('modules.i18n.error.cannotLoadUiData', 'ru'), error.toString());
            },
            () => {
                this.loadingRuUiData = false;
            },
        );
    }
    // endregion


    // region Фильтры
    private codeFilter = '';
    private enFilter = '';
    private kkFilter = '';
    private ruFilter = '';
    private descriptionFilter = '';
    private showServiceTemplates = false;

    private tmpCodeFilter = '';
    private tmpEnFilter = '';
    private tmpKkFilter = '';
    private tmpRuFilter = '';
    private tmpDescriptionFilter = '';
    private tmpShowServiceTemplates = false;

    private get preparedCodeFilter(): string | null {
        const result = this.codeFilter.trim();
        if (result.length === 0) {
            return null;
        } else {
            return result.toLowerCase();
        }
    }

    private get preparedEnFilter(): string | null {
        const result = this.enFilter.trim();
        if (result.length === 0) {
            return null;
        } else {
            return result.toLowerCase();
        }
    }

    private get preparedKkFilter(): string | null {
        const result = this.kkFilter.trim();
        if (result.length === 0) {
            return null;
        } else {
            return result.toLowerCase();
        }
    }

    private get preparedRuFilter(): string | null {
        const result = this.ruFilter.trim();
        if (result.length === 0) {
            return null;
        } else {
            return result.toLowerCase();
        }
    }

    private get preparedDescriptionFilter(): string | null {
        const result = this.descriptionFilter.trim();
        if (result.length === 0) {
            return null;
        } else {
            return result.toLowerCase();
        }
    }

    private get hasFilters(): boolean {
        return (
            (this.preparedCodeFilter !== null)
            ||
            (this.preparedEnFilter !== null)
            ||
            (this.preparedKkFilter !== null)
            ||
            (this.preparedRuFilter !== null)
            ||
            (this.preparedDescriptionFilter !== null)
        );
    }

    private hideFilters() {
        const filtersDropdown = (this.$refs.filtersDropdown as unknown as { hide(returnFocus: boolean): void; });
        filtersDropdown.hide(true);
    }

    private applyFilters() {
        this.codeFilter = this.tmpCodeFilter;
        this.enFilter = this.tmpEnFilter;
        this.kkFilter = this.tmpKkFilter;
        this.ruFilter = this.tmpRuFilter;
        this.descriptionFilter = this.tmpDescriptionFilter;
        this.showServiceTemplates = this.tmpShowServiceTemplates;
        this.hideFilters();
    }

    private resetTmpFilters() {
        this.tmpCodeFilter = this.codeFilter;
        this.tmpEnFilter = this.enFilter;
        this.tmpKkFilter = this.kkFilter;
        this.tmpRuFilter = this.enFilter;
        this.tmpDescriptionFilter = this.descriptionFilter;
        this.tmpShowServiceTemplates = this.showServiceTemplates;
    }

    private resetFilters() {
        this.codeFilter = '';
        this.enFilter = '';
        this.kkFilter = '';
        this.ruFilter = '';
        this.descriptionFilter = '';
        this.showServiceTemplates = false;
        this.resetTmpFilters();
        this.hideFilters();
    }
    // endregion


    // region Навигация по страницам
    private itemPerPage = 25;
    private totalItems = 0;
    private page = 0;
    private pageCount = 0;
    private skipLoading = false;

    private get pages(): Array<number> {
        if (this.totalItems === 0) {
            return [0];
        } else {
            const pageCount = Math.ceil(this.totalItems / this.itemPerPage);

            const result: Array<number> = [];
            for (let pageIndex = 0; pageIndex < pageCount; pageIndex++) {
                result.push(pageIndex);
            }
            return result;
        }
    }

    private get pageOptions(): Array<{ text: string, value: number }> {
        return this.pages.map((page) => ({
            text: String(page + 1),
            value: page,
        }));
    }

    private itemPerPageOptions: Array<{ text: string, value: number }> = [15, 25, 50, 100].map((value) => ({
        text: String(value),
        value: value,
    }));
    // endregion


    // region Переводы
    // noinspection JSMismatchedCollectionQueryUpdate
    private translates: Array<DictTranslate> = [];
    private loadingTranslates = false;

    private reloadTranslates() {
        if (this.loadingTranslates) {
            console.error('Cannot reload translates - another loading is running');
            return;
        }

        if (this.skipLoading) {
            return;
        }

        this.translates = [];
        this.loadingTranslates = true;

        const urlParts = [
            '/api/i18n-db/translate/list?',
            'page=', this.page,
            '&',
            'items-per-page=', this.itemPerPage,
        ];

        const addFilter = (paramName: string, filterValue: string | null) => {
            if (filterValue === null) {
                return;
            }
            urlParts.push('&', paramName, '=', encodeURIComponent(filterValue));
        };
        addFilter('filter-code', this.preparedCodeFilter);
        addFilter('filter-en', this.preparedEnFilter);
        addFilter('filter-kk', this.preparedKkFilter);
        addFilter('filter-ru', this.preparedRuFilter);
        addFilter('filter-description', this.preparedDescriptionFilter);
        if (this.showServiceTemplates) {
            addFilter('show-service-templates', 'true');
        }

        Ax<PaginationList<DictTranslate>>(
            { url: urlParts.join('') },
            (data) => {
                this.translates = data.items;
                this.totalItems = data.itemCount;
                this.pageCount = data.pageCount;

                if (data.page !== this.page) {
                    this.page = data.page;
                    this.skipLoading = true;
                    this.$nextTick(() => {
                        setTimeout(() => {
                            this.skipLoading = false;
                        });
                    });
                }
            },
            (error) => {
                this.toast('danger', i18nDb.translateByKey('modules.i18n.error.cannotLoadTranslates'), error.toString());
            },
            () => {
                this.loadingTranslates = false;
            },
        );
    }
    // endregion


    // region Редактируемый элемент
    private editorVisible = false;
    private editorNewItem = false;
    private editorDeletionAllowed = false;
    private editionOriginalTranslate: DictTranslate = emptyTranslate;
    private editionChangedTranslate: DictTranslate = emptyTranslate;

    private deletionConfirmationVisible = false;

    private get editionHasChanges(): boolean {
        return (
            (this.editionOriginalTranslate.code !== this.editionChangedTranslate.code)
            ||
            (this.editionOriginalTranslate.en !== this.editionChangedTranslate.en)
            ||
            (this.editionOriginalTranslate.kk !== this.editionChangedTranslate.kk)
            ||
            (this.editionOriginalTranslate.ru !== this.editionChangedTranslate.ru)
            ||
            (this.editionOriginalTranslate.description !== this.editionChangedTranslate.description)
        );
    }


    private showEditor(newItem: boolean, translate: DictTranslate) {
        this.editionOriginalTranslate = translate;
        this.editionChangedTranslate = translate;
        this.editorNewItem = newItem;
        this.editorDeletionAllowed = !(newItem || isProtectedCode(translate.code));
        this.editorVisible = true;
    }

    private onItemClick(translate: DictTranslate) {
        this.showEditor(false, translate);
    }

    private onCreateClick() {
        this.showEditor(true, emptyTranslate);
    }

    private onEditorDelete() {
        this.deletionConfirmationVisible = true;
    }

    private onEditorCancel() {
        this.editorVisible = false;
    }

    private onEditorOk() {
        if (this.loadingTranslates) {
            console.error('Cannot save translate - another loading is running');
            return;
        }

        this.loadingTranslates = true;
        let success = true;
        Ax(
            {
                method: 'POST',
                url: '/api/i18n-db/translate',
                data: this.editionChangedTranslate,
            },
            () => {
                success = true;
                this.toast('success', '', this.t('modules.i18n.saved'));
            },
            (error) => {
                success = false;
                this.toast('danger', this.t('modules.i18n.error.cannotSaveTranslate'), error.toString());
            },
            () => {
                this.loadingTranslates = false;
                if (success) {
                    this.editorVisible = false;
                    this.reloadTranslates();
                }
            },
        )
    }

    private onDeletionCancel() {
        this.deletionConfirmationVisible = false;
    }

    private onDeletionConfirm() {
        this.deletionConfirmationVisible = false;

        if (this.loadingTranslates) {
            console.error('Cannot delete translate - another loading is running');
            return;
        }

        this.loadingTranslates = true;
        let success = true;
        Ax(
            {
                method: 'DELETE',
                url: `/api/i18n-db/translate/${this.editionChangedTranslate.code}`,
            },
            () => {
                success = true;
                this.toast('success', '', this.t('modules.i18n.deleted'));
            },
            (error) => {
                success = false;
                this.toast('danger', this.t('modules.i18n.error.cannotDeleteTranslate'), error.toString());
            },
            () => {
                this.loadingTranslates = false;
                if (success) {
                    this.editorVisible = false;
                    this.reloadTranslates();
                }
            },
        );
    }
    // endregion


    private exportAllToExcel() {
        const url = `${location.protocol}//${location.host}/api/i18n-db/export.xlsx`
        window.open(url, '_blank', 'noopener,noreferrer', false);
    }
}
