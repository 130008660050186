
























































import { Component, Prop, Vue } from 'vue-property-decorator';
import i18nDb from '@/services/i18n-db';
import type { I18nDb } from '@/services/i18n-db';
import type { DictTranslate } from './types';


@Component
export default class ItemCard extends Vue {
    // region Свойства
    @Prop({
        type: Object,
        required: true,
    })
    private readonly item!: DictTranslate;
    // endregion


    // region Утилиты
    private t(key: string, ...args: Array<unknown>): string {
        return i18nDb.translateByKey(key, ...args);
    }
    // endregion


    private onClick() {
        this.$emit('click', this.item);
    }
}
